<template>

    <div v-bind="$attrs" class="overflow-scroll border">

        <table class="overflow-x-hidden">
            <thead class="sticky top-0 z-30">
            <tr>
                <th class="frozen" rowspan="3">{{ $t('finance.table.yearHeader') }}</th>

                <th v-for="(header,index) in level0HeaderColumns" :key="index"
                    :class="{frozen:header.frozen}" :colspan="header.colspan||1"
                    :rowspan="header.rowspan || 1">
                    <div class="">
                        <span class="sticky left-20 capitalize">{{ header.label }}</span>
                    </div>
                </th>
            </tr>

            <tr>
                <th v-for="(header, index) in level1HeaderColumns" :key="index"
                    :colspan="header.colspan || 1">
                    <div>
                        <span class="sticky left-20">{{ header.label }}</span>
                    </div>
                </th>
            </tr>

            <tr>
                <th v-for="(header, index) in filteredHeaders" :key="index"
                    v-tooltip="{value: header.tooltip, disabled: (header.tooltip === undefined)}"
                    :class="{frozen:header.frozen}">
                    {{ header.label || '' }}
                </th>
            </tr>

            </thead>

            <tbody>

            <template v-if="ready">
                <tr v-for="(step) in target.financialAnalysisNext" :key="step.step">
                    <th class="frozen">
                        <span v-if="step.step > 0">N + {{step.step}}</span>
                        <span v-else>Ref</span>
                    </th>

                    <template v-for="(column, index) in filteredColumns" :key="index">
                        <td class="text-right">
                            {{ column.value(step) }}
                            <unit v-html="column.unit"></unit>
                        </td>
                    </template>

                </tr>
            </template>

            </tbody>
            <tfoot></tfoot>

        </table>

    </div>

</template>

<script>

import dataUtils from "../../../mixins/dataUtils";
import formatUtils from "../../../mixins/formatUtils";
import energyUtils from "../../../mixins/energyUtils";

export default {
    name: "ProjectFinancialAnalysisDetails",
    props: {
        target: Object,
        displayPrimaryEnergy: Boolean,
        displayBaseConso: Boolean,
        displayExtraConso: Boolean,
        displaySavings: Boolean,
        displayNPV: Boolean
    },
    mixins: [dataUtils, formatUtils, energyUtils],
    data() {
        return {
            showPrimaryEnergy: this.displayPrimaryEnergy,
            showBase: this.displayBaseConso,
            showExtra: this.displayExtraConso,
            showSaving: this.displaySavings,
            showNPV: this.displayNPV,
        }
    },
    computed: {
        ready() {
            return this.target || false
        },

        filteredEnergyTypes() {
            const projectEnergies = this.getEnergies(this.target?.energyFlow)
            return this.energyTypes.filter(x => projectEnergies.includes(x.value))
        },


        level0HeaderColumns() {
            const headers = []
            // energy cols
            this.filteredEnergyTypes.forEach(energy => {
                const span = this.filteredColumns.filter(x => x.energy?.value === energy.value).length
                headers.push({
                    energy,
                    label: energy.label,
                    colspan: span
                })
            })

            const totalSpan = this.filteredColumns.filter(x => x.energy === undefined).length
            headers.push({
                label: this.$t('finance.table.totalHeader'),
                colspan: totalSpan
            })

            return headers
        },

        level1HeaderColumns() {
            const headers = []

            this.filteredEnergyTypes.forEach(energy => {
                const energyCols = this.filteredHeaders.filter(x => x.energy?.value === energy.value)
                const consoSpan = energyCols.filter(x => x.cat === 'conso').length
                const financeSpan = energyCols.filter(x => x.cat === 'finance').length
                headers.push({
                    energy,
                    label: this.$t('finance.table.consumptionHeader'),
                    colspan: consoSpan
                })
                headers.push({
                    energy,
                    label: this.$t('finance.table.financeHeader'),
                    colspan: financeSpan
                })
            })

            const totalConsoSpan = this.filteredHeaders.filter(x => x.energy === undefined && x.cat === 'conso').length
            const totalFinanceSpan = this.filteredHeaders.filter(x => x.energy === undefined && x.cat === 'finance').length

            headers.push({
                label: this.$t('finance.table.consumptionHeader'),
                colspan: totalConsoSpan
            })

            headers.push({
                label: this.$t('finance.table.financeHeader'),
                colspan: totalFinanceSpan
            })

            return headers
        },

        headerColumns() {
            const headers = []

            this.filteredEnergyTypes.forEach(energy => {

                /**  CONSOMMATION **/

                /** conso base **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.baseConso.short'),
                    tooltip: this.$t('finance.table.baseConso.full', [energy.label, 'kWh']),
                    filter: 'base', cat: 'conso'
                })

                /** base conso % **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.baseConsoDistribution.short'),
                    tooltip: this.$t('finance.table.baseConsoDistribution.full', [energy.label, '%']),
                    filter: 'base', cat: 'conso'
                })

                /** base conso ep **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.primaryEnergyBaseConso.short'),
                    tooltip: this.$t('finance.table.primaryEnergyBaseConso.full', [energy.label]),
                    filter: ['base', 'primary'], cat: 'conso'
                })

                /** base conso ep % **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.primaryEnergyBaseConsoDistribution.short'),
                    tooltip: this.$t('finance.table.primaryEnergyBaseConsoDistribution.full', [energy.label]),
                    filter: ['base', "primary"], cat: 'conso'
                })

                /** extra conso **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.extraConso.short'),
                    tooltip: this.$t('finance.table.extraConso.full', [energy.label]),
                    filter: 'extra', cat: 'conso'
                })

                /** extra conso % **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.extraConsoDistribution.short'),
                    tooltip: this.$t('finance.table.extraConsoDistribution.full', [energy.label]),
                    filter: 'extra', cat: 'conso'
                })

                /** extra const ep **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.primaryEnergyExtraConso.short'),
                    tooltip: this.$t('finance.table.primaryEnergyExtraConso.full', [energy.label]),
                    filter: ['extra', 'primary'], cat: 'conso'
                })

                /** extra conso ep % **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.primaryEnergyExtraConsoDistribution.short'),
                    tooltip: this.$t('finance.table.primaryEnergyExtraConsoDistribution.full', [energy.label]),
                    filter: ['extra', 'primary'], cat: 'conso'
                })


                /** reduction consommation % **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.energySavingPercentage.short'),
                    tooltip: this.$t('finance.table.energySavingPercentage.full', [energy.label, '%']),
                    filter: 'saving', cat: 'conso'
                })

                /** reduction consommation **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.energyConsumptionSaving.short'),
                    tooltip: this.$t('finance.table.energyConsumptionSaving.full', [energy.label, 'kWh']),
                    filter: 'saving', cat: 'conso'
                })

                /** reduction consommation energy primaire **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.primaryEnergyConsumptionSaving.short'),
                    tooltip: this.$t('finance.table.primaryEnergyConsumptionSaving.full', [energy.label, 'kWhEP']),
                    filter: ['saving', 'primary'], cat: 'conso'
                })


                /** consommation **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.consumption.short'),
                    tooltip: this.$t('finance.table.consumption.full', [energy.label, 'kWh']),
                    unit: 'kWh',
                    filter: 'none',
                    cat: 'conso'
                })

                /** consommation % **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.consumptionDistribution.short'),
                    tooltip: this.$t('finance.table.consumptionDistribution.full', [energy.label, '%']),
                    filter: 'none', cat: 'conso'
                })

                /** consommation energy primaire **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.primaryEnergyConsumption.short'),
                    tooltip: this.$t('finance.table.primaryEnergyConsumption.full', [energy.label, 'kWh']),
                    filter: 'primary', cat: 'conso'
                })

                /** consommation energy primaire % **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.primaryEnergyConsumptionDistribution.short'),
                    tooltip: this.$t('finance.table.primaryEnergyConsumptionDistribution.full', [energy.label, '%']),
                    filter: 'primary', cat: 'conso'
                })


                /** COUTS **/
                /** inflation **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.inflation.short'),
                    tooltip: this.$t('finance.table.inflation.full', [energy.label, '%']),
                    filter: 'none', cat: 'finance'
                })

                /** prix unitaire **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.unitPrice.short'),
                    tooltip: this.$t('finance.table.unitPrice.full', [energy.label, '€']),
                    filter: 'none', cat: 'finance'
                })

                /** cout **/

                /** base cost **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.baseCost.short'),
                    tooltip: this.$t('finance.table.baseCost.full', [energy.label]),
                    filter: 'base', cat: 'finance'
                })

                /** extra cost **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.extraCost.short'),
                    tooltip: this.$t('finance.table.extraCost.full', [energy.label]),
                    filter: 'extra', cat: 'finance'
                })

                /** extra cost % **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.extraCostDistribution.short'),
                    tooltip: this.$t('finance.table.extraCostDistribution.full', [energy.label]),
                    filter: 'extra', cat: 'finance'
                })

                /** extra cost cumul **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.cumulativeExtraCost.short'),
                    tooltip: this.$t('finance.table.cumulativeExtraCost.full', [energy.label]),
                    filter: 'extra', cat: 'finance'
                })

                /** saving costs **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.costSaving.short'),
                    tooltip: this.$t('finance.table.costSaving.full', [energy.label]),
                    filter: "saving", cat: "finance"
                })

                /** saving cost % **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.costSavingDistribution.short'),
                    tooltip: this.$t('finance.table.costSavingDistribution.full', [energy.label]),
                    filter: 'saving', cat: 'finance'
                })

                /** saving cost cumul **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.cumulativeCostSaving.short'),
                    tooltip: this.$t('finance.table.cumulativeCostSaving.full', [energy.label]),
                    filter: "saving", cat: 'finance'
                })

                /** cost **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.cost.short'),
                    tooltip: this.$t('finance.table.cost.full', [energy.label, '€']),
                    filter: 'none', cat: 'finance'
                })

                /** cout cumulé **/
                headers.push({
                    energy,
                    label: this.$t('finance.table.cumulativeCost.short'),
                    tooltip: this.$t('finance.table.cumulativeCost.full', [energy.label, '€']),
                    filter: 'none', cat: 'finance'
                })


            })

            /** TOTAL **/

            /** base conso total **/
            headers.push({
                label: this.$t('finance.table.totalBaseConso.short'),
                tooltip: this.$t('finance.table.totalBaseConso.full'),
                filter: 'base', cat: 'conso'
            })

            /** base conso ep total **/
            headers.push({
                label: this.$t('finance.table.totalPrimaryEnergyBaseConso.short'),
                tooltip: this.$t('finance.table.totalPrimaryEnergyBaseConso.full'),
                filter: ['base', 'primary'], cat: 'conso'
            })

            /** extra conso total **/
            headers.push({
                label: this.$t('finance.table.totalExtraConso.short'),
                tooltip: this.$t('finance.table.totalExtraConso.full'),
                filter: ['extra'], cat: 'conso'
            })

            /** extra conso ep total **/
            headers.push({
                label: this.$t('finance.table.totalPrimaryEnergyExtraConso.short'),
                tooltip: this.$t('finance.table.totalPrimaryEnergyExtraConso.full'),
                filter: ['extra', 'primary'], cat: 'conso'
            })

            /** saving % total **/
            headers.push({
                label: this.$t('finance.table.totalEnergySavingPercentage.short'),
                tooltip: this.$t('finance.table.totalEnergySavingPercentage.full', ['%']),
                filter: 'saving', cat: 'conso'
            })

            /** reduction conso energie primaire totale % **/
            headers.push({
                label: this.$t('finance.table.totalPrimaryEnergySavingPercentage.short'),
                tooltip: this.$t('finance.table.totalPrimaryEnergySavingPercentage.full', ['%']),
                filter: ['saving', 'primary'], cat: 'conso'
            })

            /** saving conso total **/
            headers.push({
                label: this.$t('finance.table.totalEnergyConsumptionSaving.short'),
                tooltip: this.$t('finance.table.totalEnergyConsumptionSaving.full', ['kWh']),
                filter: 'saving', cat: 'conso'
            })

            /**  saving conso ep total **/
            headers.push({
                label: this.$t('finance.table.totalPrimaryEnergyConsumptionSaving.short'),
                tooltip: this.$t('finance.table.totalPrimaryEnergyConsumptionSaving.full', ['kWhEP']),
                filter: ['saving', 'primary'], cat: 'conso'
            })


            /** consommation totale **/
            headers.push({
                label: this.$t('finance.table.totalEnergyConsumption.short'),
                tooltip: this.$t('finance.table.totalEnergyConsumption.full', ['kWh']),
                filter: 'none', cat: 'conso'
            })

            /** conso energie primaire totale **/
            headers.push({
                label: this.$t('finance.table.totalPrimaryEnergyConsumption.short'),
                tooltip: this.$t('finance.table.totalPrimaryEnergyConsumption.full', ['kWhEP']),
                filter: 'primary', cat: 'conso'
            })


            /** COUTS **/

            /** base cost total **/
            headers.push({
                label: this.$t('finance.table.totalBaseCost.short'),
                tooltip: this.$t('finance.table.totalBaseCost.full'),
                filter: 'base', cat: 'finance'
            })

            /** base cost total cumul **/
            headers.push({
                label: this.$t('finance.table.totalCumulativeBaseCost.short'),
                tooltip: this.$t('finance.table.totalCumulativeBaseCost.full'),
                filter: 'base', cat: 'finance'
            })

            /** extra cost total **/
            headers.push({
                label: this.$t('finance.table.totalExtraCost.short'),
                tooltip: this.$t('finance.table.totalExtraCost.full'),
                filter: 'extra', cat: 'finance'
            })

            /** extra cost total cumul **/
            headers.push({
                label: this.$t('finance.table.totalCumulativeExtraCost.short'),
                tooltip: this.$t('finance.table.totalCumulativeExtraCost.full'),
                filter: 'extra', cat: 'finance'
            })

            /** saving cost total **/
            headers.push({
                label: this.$t('finance.table.totalSavingCost.short'),
                tooltip: this.$t('finance.table.totalSavingCost.full'),
                filter: 'saving', cat: 'finance'
            })

            /** saving cost total cumul **/
            headers.push({
                label: this.$t('finance.table.totalCumulativeSavingCost.short'),
                tooltip: this.$t('finance.table.totalCumulativeSavingCost.full'),
                filter: 'saving', cat: 'finance'
            })


            /** cout total **/
            headers.push({
                label: this.$t('finance.table.totalCost.short'),
                tooltip: this.$t('finance.table.totalCost.full', ['€']),
                filter: 'none', cat: 'finance'
            })

            /** cout cumulé **/
            headers.push({
                label: this.$t('finance.table.totalCumulativeCost.short'),
                tooltip: this.$t('finance.table.totalCumulativeCost.full', ['€']),
                filter: 'none', cat: 'finance'
            })


            /** investissement **/
            headers.push({
                label: this.$t('finance.table.investment.short'),
                tooltip: this.$t('finance.table.investment.full', ['€']),
                filter: 'npv', cat: 'finance'
            })

            /** cash flow **/
            headers.push({
                label: this.$t('finance.table.cashFlow.short'),
                tooltip: this.$t('finance.table.cashFlow.full', ['€']),
                filter: 'npv', cat: 'finance'
            })

            /** van **/
            headers.push({
                label: this.$t('finance.table.NPV.short'),
                tooltip: this.$t('finance.table.NPV.full', ['€']),
                filter: 'npv', cat: 'finance'
            })

            return headers
        },


        columns() {

            const columns = []

            this.filteredEnergyTypes.forEach(energy => {

                /** base conso **/
                columns.push({
                    energy,
                    value: (step) => this.formatDouble(step?.energyFlow.baseConso[energy.value], 0),
                    unit: 'kWh', filter: 'base'
                })

                /** base conso distribution **/
                columns.push({
                    energy,
                    value: (step) => this.formatPercent(step?.energyFlow.baseConsoSplit[energy.value]),
                    unit: '%', filter: 'base'
                })

                /** base conso EP **/
                columns.push({
                    energy,
                    value: (step) => this.formatDouble(step?.energyFlow.baseConsoPe[energy.value], 0),
                    unit: 'kWh<sub>Ep</sub>',
                    filter: ['primary', 'base']
                })

                /** base conso ep % **/
                columns.push({
                    energy,
                    // value: (step) => this.formatPercent(step?.primaryEnergyConsumptionDistribution[energy.value]),
                    value: (step) => this.formatPercent(step?.energyFlow.baseConsoPeSplit[energy.value]),
                    unit: "%",
                    filter: ['primary', 'base']
                })


                /** extra conso **/
                columns.push({
                    energy,
                    value: (step) => this.formatDouble(step?.energyFlow.extraConso[energy.value], 0),
                    unit: 'kWh',
                    filter: ['extra']
                })

                /** extra conso % **/
                columns.push({
                    energy,
                    value: (step) => this.formatPercent(step?.energyFlow.extraConsoSplit[energy.value]),
                    unit: '%',
                    filter: ['extra']
                })

                /** extra conso EP **/
                columns.push({
                    energy,
                    value: (step) => this.formatDouble(step?.energyFlow.extraConsoPe[energy.value], 0),
                    unit: 'kWh<sub>Ep</sub>',
                    filter: ['extra', 'primary']
                })

                /** extra conso EP % **/
                columns.push({
                    energy,
                    value: (step) => this.formatPercent(step?.energyFlow.extraConsoPeSplit[energy.value]),
                    unit: '%',
                    filter: ['extra', 'primary']
                })

                /** reduction consommation pourcentage **/
                columns.push({
                    energy,
                    // value: (step) => this.formatPercent(step?.energySavingPercentage[energy.value]),
                    value: (step) => this.formatPercent(step?.energyFlow.savingPercent[energy.value]),
                    unit: '%',
                    filter: 'saving'
                })

                /** reduction consommation **/
                columns.push({
                    energy,
                    // value: (step) => this.formatDouble(step?.energyConsumptionSaving[energy.value], 0),
                    value: (step) => this.formatDouble(step?.energyFlow.savingConso[energy.value], 0),
                    unit: 'kWh',
                    filter: 'saving'
                })

                /** reduction consommation energie primaire **/
                columns.push({
                    energy,
                    // value: (step) => this.formatDouble(step?.primaryEnergyConsumptionSaving[energy.value], 0),
                    value: (step) => this.formatDouble(step?.energyFlow.savingConsoPe[energy.value], 0),
                    unit: 'kWh<sub>Ep</sub>',
                    filter: ['primary', 'saving']
                })


                /** consommation **/
                columns.push({
                    energy,
                    // value: (step) => this.formatDouble(step?.energyConsumption[energy.value], 0),
                    value: (step) => this.formatDouble(step?.energyFlow.conso[energy.value], 0),
                    unit: 'kWh',
                    filter: 'none'
                })

                /** consommation proportion **/
                columns.push({
                    energy,
                    // value: (step) => this.formatPercent(step?.energyConsumptionDistribution[energy.value]),
                    value: (step) => this.formatPercent(step?.energyFlow.consoSplit[energy.value]),
                    unit: '%',
                    filter: 'none'
                })

                /** consommation energie primaire **/
                columns.push({
                    energy,
                    // value: (step) => this.formatDouble(step?.primaryEnergyConsumption[energy.value], 0),
                    value: (step) => this.formatDouble(step?.energyFlow.consoPe[energy.value], 0),
                    unit: 'kWh<sub>Ep</sub>',
                    filter: 'primary'
                })

                /** consommation energie primaire proportion **/
                columns.push({
                    energy,
                    // value: (step) => this.formatPercent(step?.primaryEnergyConsumptionDistribution[energy.value]),
                    value: (step) => this.formatPercent(step?.energyFlow.consoPeSplit[energy.value]),
                    unit: "%",
                    filter: 'primary'
                })


                /** COUT **/
                /** inflation **/
                columns.push({
                    energy,
                    // value: (step) => this.formatPercent(step?.energyUnitPriceInflationRate[energy.value], 2, 2),
                    value: (step) => this.formatPercent(step?.inflationRate[energy.value], 2, 2),
                    unit: '%',
                    filter: 'none'
                })

                /** prix unitaire  **/
                columns.push({
                    energy,
                    // value: (step) => this.formatDouble(step?.energyUnitPrice[energy.value], 4, 4),
                    value: (step) => this.formatDouble(step?.moneyFlow.unitPrice[energy.value], 4, 4),
                    unit: '€',
                    filter: 'none'
                })

                /** base cost **/
                columns.push({
                    energy,
                    value: (step) => this.formatCurrency(step?.moneyFlow.baseCost[energy.value]),
                    unit: '€',
                    filter: 'base'
                })

                /** extra cost **/
                columns.push({
                    energy,
                    value: (step) => this.formatCurrency(step?.moneyFlow.extraCost[energy.value]),
                    unit: '€',
                    filter: 'extra'
                })

                /** extra cost % **/
                columns.push({
                    energy,
                    value: (step) => this.formatPercent(step?.moneyFlow.extraCostSplit[energy.value]),
                    unit: '€',
                    filter: 'extra'
                })

                /** extra cost cumul **/
                columns.push({
                    energy,
                    value: (step) => this.formatCurrency(step?.cumulativeExtraCost[energy.value]),
                    unit: '€',
                    filter: 'extra'
                })

                /** saving cost **/

                columns.push({
                    energy,
                    value: (step) => this.formatCurrency(step?.moneyFlow.savingCost[energy.value]),
                    unit: '€',
                    filter: 'saving'
                })

                /** saving cost % **/
                columns.push({
                    energy,
                    value: (step) => this.formatPercent(step?.moneyFlow.savingCostSplit[energy.value]),
                    unit: '%',
                    filter: 'saving'
                })

                /** saving cost cumul **/
                columns.push({
                    energy,
                    value: (step) => this.formatCurrency(step?.cumulativeSavingCost[energy.value]),
                    unit: '€',
                    filter: 'saving'
                })

                /** cout **/
                columns.push({
                    energy,
                    // value: (step) => this.formatCurrency(step?.energyCost[energy.value]),
                    value: (step) => this.formatCurrency(step?.moneyFlow.cost[energy.value]),
                    unit: '€',
                    filter: 'none'
                })

                /** cout cumulé **/
                columns.push({
                    energy,
                    // value: (step) => this.formatCurrency(step?.cumulativeEnergyCost[energy.value]),
                    value: (step) => this.formatCurrency(step?.cumulativeCost[energy.value]),
                    unit: '€',
                    filter: 'none'
                })

            })

            /** TOTAL **/

            /** base conso total **/
            columns.push({
                value: (step) => this.formatDouble(step?.energyFlow.totalBaseConso, 0),
                unit: 'kWh',
                filter: 'base'
            })

            /** base conso total ep **/
            columns.push({
                value: (step) => this.formatDouble(step?.energyFlow.totalBaseConsoPe, 0),
                unit: 'kWh<sub>Ep</sub>',
                filter: ['base', 'primary']
            })

            /** extra conso total **/
            columns.push({
                value: (step) => this.formatDouble(step?.energyFlow.totalExtraConso, 0),
                unit: 'kWh',
                filter: 'extra'
            })

            /** extra conso total ep **/
            columns.push({
                value: (step) => this.formatDouble(step?.energyFlow.totalExtraConsoPe, 0),
                unit: 'kWh<sub>Ep</sub>',
                filter: ['extra', 'primary']
            })


            /** réduction conso totales % **/
            columns.push({
                value: (step) => this.formatPercent(step?.energyFlow.totalSavingPercent),
                unit: '%',
                filter: 'saving'
            })

            /** reduction conso primaire total % **/
            columns.push({
                value: (step) => this.formatPercent(step?.energyFlow.totaSavingPercentPe),
                unit: '%',
                filter: ['saving', 'primary']
            })

            /** reduction conso totale **/
            columns.push({
                value: (step) => this.formatDouble(step?.energyFlow.totalSavingConso, 0),
                unit: 'kWh',
                filter: 'saving'
            })

            /** reduction conso primaire totale **/
            columns.push({
                value: (step) => this.formatDouble(step?.energyFlow.totalSavingConsoPe, 0),
                unit: 'kWh<sub>Ep</sub>',
                filter: ['saving', 'primary']
            })


            /** consommation totale **/
            columns.push({
                value: (step) => this.formatDouble(step?.energyFlow.totalConso, 0),
                unit: 'kWh',
                filter: 'none'
            })

            /** consommation energie primaire totale **/
            columns.push({
                value: (step) => this.formatDouble(step?.energyFlow.totalConsoPe, 0),
                unit: 'kWh<sub>Ep</sub>',
                filter: 'primary'
            })


            /** cout **/


            /** base cost total **/
            columns.push({
                value: (step) => this.formatCurrency(step?.moneyFlow.totalBaseCost),
                unit: '€',
                filter: 'base'
            })

            /** base cost total cumul **/
            columns.push({
                value: (step) => this.formatCurrency(step?.totalCumulativeBaseCost),
                unit: '€',
                filter: 'base'
            })

            /** extra cost total **/
            columns.push({
                value: (step) => this.formatCurrency(step?.moneyFlow.totalExtraCost),
                unit: '€',
                filter: 'extra'
            })

            /** extra cost total cumul **/
            columns.push({
                value: (step) => this.formatCurrency(step?.totalCumulativeExtraCost),
                unit: '€',
                filter: 'extra'
            })

            /** saving cost total **/
            columns.push({
                value: (step) => this.formatCurrency(step?.moneyFlow.totalSavingCost),
                unit: '€',
                filter: 'saving'
            })

            /** saving cost total cumul **/
            columns.push({
                value: (step) => this.formatCurrency(step?.totalCumulativeSavingCost),
                unit: '€',
                filter: 'saving'
            })


            /** cout total **/
            columns.push({
                value: (step) => this.formatCurrency(step?.moneyFlow.totalCost),
                unit: '€',
                filter: 'none'
            })

            /** cout total cumulé **/
            columns.push({
                value: (step) => this.formatCurrency(step?.totalCumulativeCost),
                unit: '€',
                filter: 'none'
            })

            /** investissement **/
            columns.push({
                value: (step) => this.formatCurrency(step?.moneyFlow.investmentCost),
                unit: '€',
                filter: 'npv'
            })

            /** cash flow **/
            columns.push({
                value: (step) => this.formatCurrency(step?.cashFlow),
                unit: '€',
                filter: 'npv'
            })

            /** van **/
            columns.push({
                value: (step) => this.formatCurrency(step?.netPresentValue),
                unit: '€',
                filter: 'npv'
            })

            return columns
        },

        filteredColumns() {
            return this.columns.filter(this.columnFilter)
        },

        filteredHeaders() {
            return this.headerColumns.filter(this.columnFilter)
        }


    },
    methods: {

        columnFilter(column) {
            let filter = column.filter || ['none']
            filter = Array.isArray(filter) ? filter : [filter]
            return filter.every(x => {
                return x === 'none' ||
                    (x === 'base' && this.showBase) ||
                    (x === 'extra' && this.showExtra) ||
                    (x === 'primary' && this.showPrimaryEnergy) ||
                    (x === 'saving') && this.showSaving ||
                    (x === 'npv') && this.showNPV
            })
        },
    }
}
</script>

<style scoped>

table {
    border-spacing: 0;
    @apply w-full table-auto whitespace-nowrap border-separate;

    & unit {
        @apply text-gray-400;
    }

    & thead th {

        @apply py-1;
    }


    & th {
        background-color: #f8f9fa;
        @apply font-semibold z-10;

        &.frozen {
            @apply sticky left-0;
        }
    }

    & th, td {
        @apply text-left px-2 py-2;
    }

    & td {
        @apply border-b border-r;
    }

    & th {
        @apply border-b border-r;
    }
}

</style>